import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as LogRocket from 'logrocket';
import { Observable, Subscription, timer } from 'rxjs';
import { AccountOutput } from 'src/app/shared/models';
import { AccountDetailsModel } from 'src/app/shared/models/account-details.model';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';
import { AccountDetails } from 'src/app/shared/services/account-details.prod';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { CheckAuthService } from 'src/app/shared/services/checkLogin.service';
import { Logger } from 'src/app/shared/services/logger.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-unauth',
  templateUrl: './unauth.component.html'
})
export class UnauthComponent implements OnInit {
  public config: EnvironmentData;

  public error: string = '';
  public selectedAccountId: string = '';
  public encKey: string = '';
  public sessionId: string = '';
  public dateTimePassedByAma: string = new Date().toString();
  public showUserDropdown: boolean = false;
  public accountDetails: AccountDetailsModel[] = AccountDetails;
  private subscription: Subscription;
  private refreshTime: Observable<number> = timer(0, 60000);

  constructor(
    public authService: AuthorizationService,
    private loginService: LoginService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private logger: Logger,
    private toastService: ToastService,
    private route: ActivatedRoute,
    public service: CheckAuthService
  ) { }

  public ngOnInit(): void {
    this.loadConfig()
    this.encKey = sessionStorage.getItem('paLoginKey');
    this.sessionId = sessionStorage.getItem('uid');
  }

  public loadConfig(): void {
    this.authService.getConfig().subscribe(res => {
      this.config = res;
      sessionStorage.setItem('config', JSON.stringify(res));
      this.service.loadConfig(res.CheckUrl).subscribe(resp => {
        let ak = resp.headers.get('Content-Cf-Ak');
        let dk = resp.headers.get('Content-Cf-Dk');
        let cp = resp.headers.get('Content-Cf-Cp');
        let gs = resp.headers.get('Content-Cf-Gs');
        let chkAK = atob(ak);
        let chkDK = atob(dk);
        let chkCP = atob(cp);
        let chkGS = atob(gs);
        if(sessionStorage.getItem('ak') && sessionStorage.getItem('ak')===chkAK) {
          this.encKey = sessionStorage.getItem('paLoginKey');
          this.getConfiguration(this.encKey);
        } else {
          sessionStorage.setItem('ak', chkAK);
          sessionStorage.setItem('dk', chkDK);
          sessionStorage.setItem('cp', chkCP);
          sessionStorage.setItem('gs', chkGS);
          this.encKey = sessionStorage.getItem('paLoginKey');
          this.getConfiguration(this.encKey);
        }
      }, err => {
        this.encKey = null
        console.log("Error");
      });
    });
  }

  private getConfiguration(key): void {
    this.authService.getConfig().subscribe(res => {
      this.config = res;
      sessionStorage.setItem('config', JSON.stringify(res));
      this.sessionIdleCheck();
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
      if (this.sessionId && this.sessionId !== 'null') {
        this.validateRequest();
      }
      if (key) {
        this.doSSOLogin(key);
      }
    }, (err: Response) => {
      this.logger.error(err['error'].message);
      this.showDanger('error in service:' + err['error'].message);
    });
  }

  private validateRequest(): void {
    this.authService.checkSession(this.sessionId).subscribe(res => {
      this.selectedAccountId = res['account_id'];
      this.getAccountDetails(this.selectedAccountId);
    }, (err: Response) => {
      this.sessionId = null;
      this.logger.error(err['error'].message);
      this.showDanger('error in service:' + err['error'].message);
    });
  }

  public getAccountDetails(accountId: string): void {
    this.authService.getAccountDetails(accountId).subscribe(res => {
      var loggedInDateTime = new Date(this.dateTimePassedByAma);
      var currentDateTime = new Date(res['current_date_time']);
      loggedInDateTime.setMinutes(loggedInDateTime.getMinutes() + 10);
      if (loggedInDateTime < currentDateTime) {
        this.showDanger('Login token time has expired/Invalid token.');
        return;
      }
      sessionStorage['isValidUser'] = true;
      sessionStorage.setItem('accountDetails', JSON.stringify(res[0]));
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
      this.logRocket(res[0]);
      sessionStorage.setItem('showFirstTimeWelcome', 'true');
      this.validateSite(res[0]['username'])
    }, (err: Response) => {
      this.showDanger('error in service:' + err['error'].message);
      this.logger.error(err['error'].message);
    });
  }

  public validateSite(username: string): void {
    this.loginService.username_lookup(username).subscribe((data) => {
      let amaDetails = data.body[0];
      if (amaDetails['website_product_name'] === 'RC2') {
        this.router.navigate(['/layout']);
      } else if (amaDetails['website_product_name'] === 'RCCALite') {
        window.location.replace(this.config.RCCALiteUrl + '/?sid=' + this.sessionId + '&uid=' + this.sessionId)
      } else if (amaDetails['website_product_name'] === 'RCClaimAssist') {
        window.location.replace(this.config.RCCAUrl + '/?sid=' + this.sessionId + '&uid=' + this.sessionId)
      } else if (amaDetails['website_product_name'] === 'RCNDCXWalk') {
        window.location.replace(this.config.RcNdcXwalkUrl + '/?sid=' + this.sessionId + '&uid=' + this.sessionId)
      } else {
        this.sessionId = null;
      }
    });

  }

  public sessionIdleCheck(): void {
    this.subscription = this.refreshTime.subscribe((seconds) => {
      this.authService.getConfig().subscribe(res => {
        if (res['ApplicationVersionNo'] !== (JSON.parse(sessionStorage.getItem('config'))).ApplicationVersionNo) {
          this.config = res;
          sessionStorage.setItem('config', JSON.stringify(res));
          this.logger.debug('Refreshed config');
        }
      }, (err: Response) => {
        this.logger.error(err['error'].message);
      });
    });
  }

  public logRocket(accountDetails: AccountOutput): void {
    if (!this.config) {
      this.authService.getConfig().subscribe(res => {
        sessionStorage.setItem('config', JSON.stringify(res));
        this.identifyLogRocket(accountDetails, res['CurrentEnvironment']);
      }, (err: Response) => {
        this.logger.error(err['error'].message);
        this.showDanger('error in service:' + err['error'].message);
      });
    } else {
      this.identifyLogRocket(accountDetails, this.config['CurrentEnvironment']);
    }
  }

  private identifyLogRocket(accountDetails: AccountOutput, env: string): void {
    // only enable LR for UAT env
    if (env === 'UAT') {
      // filter out automated testing user
      if (accountDetails.username !== 'AutoFSUser1') {
        LogRocket.init('rj-health/rc3');
        LogRocket.identify('rj-health/rc3', {
          name: accountDetails.username,
          email: accountDetails.email,
        });
      }
    }
  }

  public showDanger(dangerTpl: string): void {
    this.logger.info("service error: " + dangerTpl);
  }

  doSSOLogin(key: string) {
    this.authService.decrypt(key).subscribe(res => {
      sessionStorage.removeItem('paLoginKey');
      let decodedMessage = res;
      let splittedText = decodedMessage.split('!');
      let splittedDate = splittedText[0];			
      let accountUsername = splittedText[2].split('\\u0000')[0].trim();	
      let splitYear= +splittedDate.substring(4, 0);
      let splitMonth: number = +splittedDate.substring(6, 4);			
          splitMonth = splitMonth - 1;
      let splitDay = +splittedDate.substring(8, 6);
      
      let newDate = new Date()
      newDate.setUTCDate(splitDay)
      newDate.setUTCFullYear(splitYear)
      newDate.setUTCMonth(splitMonth)
      newDate.setHours(0)
      newDate.setMinutes(0)
      newDate.setSeconds(0)

      let currentDate = new Date();
      currentDate.setHours(0,0,0,0);
      this.encKey = null
      if (newDate.toString() == currentDate.toString()) {	
      
      this.dcluEncryptPassword(accountUsername);
      } else {
        this.router.navigate(['/access-denied-rc2']);
      }

    }, (err: Response) => {
      this.logger.error(err['error'].message);
      this.showDanger('error in service:' + err['error'].message);

    });
  }

  private dcluEncryptPassword(accountUsername) {
    let userPassEncrypt = sessionStorage.getItem('dk')
    this.loginSubmit(accountUsername, userPassEncrypt, true);
  }

  loginSubmit(username, password, register: boolean) {
    let jsonData =
    {
      'username': username,
      'password': password
    };
      this.loginService.getAmaLoginDetails(jsonData).subscribe(res => {
        if (res && res[0]['session_id']) {
          if (res[0]['termination_date']) {
            let isTerminated = this.checkValidDate(res[0]['termination_date']);
            if (isTerminated) {
              this.encKey = null
              alert('Your account has been terminated');
              this.router.navigate(['/access-denied-rc2']);
            } else {
              sessionStorage.setItem('uid', res[0]['session_id']);
              this.selectedAccountId = res[0]['account_id'];
              this.getAccountDetails(this.selectedAccountId);
            }    
          }
        } else if (!res){
          if (register) {
            let orgId = this.config["DCLU_ORG_ID"];
            let email = username+'@pa.com';
            let jsonData = 
              {"website_product_name":"RC2",
                "orgid": orgId,
                "contact_first": username,
                "contact_last":"PA User",
                "title":"",
                "department":"",
                "address_1":"",
                "address_2":"",
                "city":"",
                "state":"",
                "zip":"",
                "phone":"",
                "fax":"",
                "email":email.toLowerCase(),
                "username":username.toLowerCase(),
                "password":password,
                "hear_about_us":"",
                "other":"VRFY",
                "specialty":"",
                "company_name":""
              };
            this.loginService.addAccount(jsonData).subscribe(res => {
              let verfication_aa_id = res[0].verfication_aa_id;
					    let dataKey = {"aa_id": verfication_aa_id };
              this.loginService.verifyAccount(dataKey).subscribe(res => {
                this.loginSubmit(username, password, false);
              });
              
            });
          } else {
            this.showDanger('Cannot login');
            this.router.navigate(['/access-denied-rc2']);
          }
        }
      }, err => {
        this.showDanger('Cannot login');
        this.router.navigate(['/access-denied-rc2']);
      });
  }

  private checkValidDate(dateValue: string = null) {
    const dateFilter = new Date(dateValue);
    const currentDate = new Date();
    return currentDate.getTime() > dateFilter.getTime() ? true : false
  }
}
