import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.scss']
})
export class CustomerRegistrationComponent implements OnInit {

  public counter: number = 0;
  public error: boolean = false;
  public errMsg: string = '';
  public success: boolean = false;
  public successMsg: string = '';
  public isTerminated: boolean = false;
  public status: number = 0;
  public companyDetails: any = [];
  public code: string = '';

  CodeForm = this.fb.group({
    accessCode: [null, Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    public regService: RegistrationService,
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem("counter", "0");
  }

  onDataSubmit() {
    this.error = false;
    this.success = false;
    this.code = this.CodeForm.controls.accessCode.value;
    this.CodeForm.patchValue({ accessCode: '' })
    if (this.code) {
      this.counter = parseInt(sessionStorage.getItem("counter")) ? parseInt(sessionStorage.getItem("counter")) : 0;
      this.counter = this.counter + 1;
      if (this.counter > 4) {
        alert("You have reached your maximum invalid attempts limit");
        sessionStorage.setItem("counter", "0");
        // this.router.navigate(['/free-trial'])
      }
      else {
        this.regService.companyPass(this.code).subscribe(response => {
          this.status = response.status;
          if (this.status === 200) {
            sessionStorage.setItem("counter", "0");
            this.companyDetails = response.body['companyPassView'][0]
            sessionStorage.setItem("companyDetails", JSON.stringify(this.companyDetails));
            if (this.companyDetails.companytermdate) this.checkValidDate(this.companyDetails.companytermdate);
            if (response && this.companyDetails.active_users >= this.companyDetails.num_accounts) {
              this.error = true
              this.errMsg = "Your organization's allotted user spaces are full. Please contact your company administrator or contact us at 860-563-1223.";
              setTimeout(() => this.error = false, 5000);
            } else if (this.isTerminated) {
              this.error = true;
              this.errMsg = "Your account has been terminated";
              setTimeout(() => this.error = false, 5000);
            } else {
              this.router.navigate(['/customer-account-creation'])
            }
          } else {
            sessionStorage.setItem("counter", this.counter.toString());
            this.error = true;
            this.errMsg = "Access code is incorrect. Please try again.";
            setTimeout(() => this.error = false, 5000);
          }
        })
      }
    } else {
      window.scrollTo(0, 0)
      this.error = true;
      this.errMsg = "Access code cannot be blank";
      setTimeout(() => this.error = false, 5000);
      return;
    }

  }

  checkValidDate(dateValue: string = null) {
    const dateFilter = new Date(dateValue);
    const currentDate = new Date();
    this.isTerminated = currentDate.getTime() > dateFilter.getTime() ? true : false
  }
}
